import { AdRectangle } from "@/components/adAreaRectangle";
import { Divisor } from "@/components/divisor";
import { Footer } from "@/components/footer";
import { HeaderLinks } from "@/components/headerLinks";
import { HeaderSample } from "@/components/headerSample";
import { Loading } from "@/components/loading";
import { IArticle } from "@/interfaces/article";
import { IVideo } from "@/interfaces/video";
import { clientAPIRequest } from "@/services/api";
import { editorials, strings } from "@/strings";
import styles from "@/styles/Home.module.css";
import { GetStaticProps, GetStaticPropsResult } from "next";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";

export default function Home({ noticias, videos, tecnologia, vocesabia }: Props) {
  const router = useRouter();

  if (router.isFallback) {
    return <Loading />;
  }

  return (
    <>
      <Head>
        <title>Rádio Escotilha</title>
        <meta charSet="UTF-8" />
        <meta name="author" content="Rádio escotilha news" />
        <meta name="description" content="Rádio escotilha news, a voz do povo de várzea alegre" />
        <meta name="keywords" content="rádio, escotilha, várzea alegre, ceará, notícias, música, entretenimento, informação, atualidades, horizonte, política, saúde, economia, cariri, centro-sul" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={styles.page}>
        <div>
          <HeaderLinks />
          <HeaderSample title="Rádio Escotilha" color="blue" />
        </div>
        <main className={styles.content}>
          <div className={styles.body}>
            <div className={styles.banner}>
              <img src={`/banner.svg`} alt='patrocinador' />
            </div>
            <AdRectangle />
            <section className={styles.section}>
              <div className={styles.sectionHeader}>
                <h2> Últimas Notícias</h2>
                <a href="/noticias">
                  Veja mais {">>"}
                </a>
              </div>
              <Divisor color="blue" />
              <div className={styles.newsArea}>
                <ul>
                  {
                    noticias.map(news => <li key={news.id}>
                      <a href={`/${news.editorial}/noticia/${news.id}/${news.slug}`}>
                        <div className={styles.card}>
                          <img src={`${strings.backend_url_image}/${news.thumb}`} />
                          <span>{news.title}</span>
                        </div>
                      </a>
                    </li>)
                  }
                </ul>
              </div>
            </section>
            <AdRectangle />
            <section className={styles.section}>
              <div className={styles.sectionHeader}>
                <div>
                  <h2>Videos</h2>
                  <Divisor color="red" />
                </div>
                <a href="https://www.youtube.com/@escotilhanews">
                  Veja mais {">>"}
                </a>
              </div>
              <div className={styles.newsArea}>
                <ul>
                  {
                    videos.map(news => <li key={news.videoId}>
                      <a href={`https://www.youtube.com/watch?v=${news.videoId}`}>
                        <div className={styles.card}>
                          <Image width={240} height={128} src={news.thumbnail} alt={news.title} />
                          <span>{news.title}</span>
                        </div>
                      </a>
                    </li>)
                  }
                </ul>
              </div>
            </section>
            <AdRectangle />
            <section className={styles.section}>
              <div className={styles.sectionHeader}>
                <h2> Você Sabia?</h2>
                <a href="/vocesabia">
                  Veja mais {">>"}
                </a>
              </div>
              <Divisor color="black" />
              <div className={styles.newsArea}>
                <ul>
                  {
                    vocesabia.map(news => <li key={news.id}>
                      <a href={`/${news.editorial}/noticia/${news.id}/${news.slug}`}>
                        <div className={styles.card}>
                          <img src={`${strings.backend_url_image}/${news.thumb}`} />
                          <span>{news.title}</span>
                        </div>
                      </a>
                    </li>)
                  }
                </ul>
              </div>
            </section>
            <AdRectangle />
            <section className={styles.section}>
              <div className={styles.sectionHeader}>
                <h2> Tecnologia</h2>
                <a href="/tecnologia">
                  Veja mais {">>"}
                </a>
              </div>
              <Divisor color="yellow" />
              <div className={styles.newsArea}>
                <ul>
                  {
                    tecnologia.map(news => <li key={news.id}>
                      <a href={`/${news.editorial}/noticia/${news.id}/${news.slug}`}>
                        <div className={styles.card}>
                          <img src={`${strings.backend_url_image}/${news.thumb}`} />
                          <span>{news.title}</span>
                        </div>
                      </a>
                    </li>)
                  }
                </ul>
              </div>
            </section>
            <AdRectangle />
            <section className={styles.section}>
              <h2>Colunas</h2>
              <Divisor color="red" />
              <div className={styles.column}>
                <ul>
                  {
                    editorials.filter(ed => ed.type === 'coluna').map(coluna => <li key={coluna.key}>
                      <a href={`/${coluna.url}`}>
                        <div className={styles.columncard}>
                          <div className={styles.columnAligner}>
                            <Image width={128} height={128} alt={coluna.colunista?.name ?? ''} src={`${coluna.colunista?.image}`} />
                          </div>
                          <span>{coluna.label}</span>
                          <small>por {coluna.colunista?.name}</small>
                        </div>
                      </a>
                    </li>)
                  }
                </ul>
              </div>
            </section>
            <AdRectangle />
            <section className={styles.section} id="programacao">
              <h2>Programação</h2>
              <Divisor color="black" />
              <div className={styles.program}>
                <div className={styles.programName}>
                  <h3>EscotilhaCast</h3>
                  <span>- Com Capitão Horizonte</span>
                </div>
                <div className={styles.programHour}>
                  <span>Toda segunda às 19h</span>
                </div>
              </div>
              <div className={styles.program}>
                <div className={styles.programName}>
                  <h3>Jovens Atalaias em Ação</h3>
                  <span>- Com Igreja COMADESMA</span>
                </div>
                <div className={styles.programHour}>
                  <span>Toda quarta às 19h30</span>
                </div>
              </div>
              <div className={styles.program}>
                <div className={styles.programName}>
                  <h3>Programa Corações a Mil</h3>
                  <span>- Com Luciano Ferrari</span>
                </div>
                <div className={styles.programHour}>
                  <span>De segunda a sexta a partir das 20h</span>
                </div>
              </div>
            </section>
          </div>
        </main>
        <Footer color="blue" />
      </div>
    </>
  );
}


interface Props {
  noticias: IArticle[]
  tecnologia: IArticle[]
  vocesabia: IArticle[]
  videos: IVideo[]
}

export const getStaticProps: GetStaticProps<Props> =
  async (ctx): Promise<GetStaticPropsResult<Props>> => {

    const apiClient = clientAPIRequest(ctx)

    const { data: videos } = await apiClient.get(`/articles/videos`)
    const { data: { articles: noticias } } = await apiClient.get(`/articles?editorial=noticias&take=5&page=1`)
    const { data: { articles: tecnologia } } = await apiClient.get(`/articles?editorial=tecnologia&take=5&page=1`)
    const { data: { articles: vocesabia } } = await apiClient.get(`/articles?editorial=vocesabia&take=5&page=1`)

    return {
      revalidate: 1800,
      props: {
        noticias,
        tecnologia,
        videos,
        vocesabia
      }
    }
  }